import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { Link, graphql } from 'gatsby'
import { MdLink } from 'react-icons/md'

class CategoryPage extends React.Component {
  render() {
    const category = this.props.data.strapiCategory

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet>
            <title>{category.name}</title>
            <meta name="og:title" content={category.name} />
            <meta name="og:type" content="article" />
          </Helmet>
          <Link to="/tutorial/">{`< 回到睡吧知识库`}</Link>
          <div className="m-b-lg"></div>
          <h1 className="title">
            {category.name} - 所有文章
          </h1>
          <ul>
            {category.articles.map(article => (
              <li>
                <MdLink />
                <Link className="p-l-sm" to={`/${article.type || 'tutorial'}/${article.id}`}>
                  {article.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default CategoryPage

export const pageQuery = graphql`
  query($id: String!) {
    strapiCategory(strapiId: { eq: $id }) {
      id: strapiId
      name
      articles {
        id
        excerpt
        title
        coverUrl
        updatedAt(formatString: "YYYY-MM-DD")
        date
      }
    }
  }
`
